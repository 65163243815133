import React from 'react';

import Layout from '../components/layout';
import Menu from '../components/menuComponent';
import Covid from '../components/covidComponent';

export default () => (
  <Layout
    title="Coronavirus covid-19 updates | Wow Interior Design"
    description="View Wow Interior Design's latest updates on how we are operating during the pandemic."
  >
    <Menu />
    <Covid />
  </Layout>
);
