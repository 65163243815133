/* eslint-disable max-len */
import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';

import covidStyles from './covid.module.css';

export default () => (
  <div className={covidStyles.covidOuterContainer}>
    <div className={covidStyles.covidContainer}>
      <div className={covidStyles.covidHeader}>
        <h1>Coronavirus</h1>
        <span className={covidStyles.covidSubHeader}>(COVID-19)</span>
      </div>
      <section className={covidStyles.covidSection}>
        <i>18th May 2020</i>
        <h3><strong>Home Design Visits</strong></h3>
        <p>
          While our office and studio still remain closed to visitors Wow Interiors are now beginning to offer design visits again.
        </p>
        <p>
          The safety of our customers and staff is extremely important so in order to keep safe during a home visit our designers will wear new face masks, disposable gloves &amp; bring hand sanitiser. During the design visit your designer will do their best to stay at least 2 metres away from anyone else while they are in your home.
        </p>
        <p>
          Despite these new precautions we believe our designers will still be able to plan your dream bespoke furniture with you.
        </p>
        <p>
          These precautions will also be taken during the installation process. Please view our
          <OutboundLink className={covidStyles.covidExternalLink} href="../../wow-Coronavirus-advice-sheet-installations.pdf">
            Coronavirus installations advice sheet
          </OutboundLink>
        </p>
        <p>
          Alternatively we are offering
          <Link className={covidStyles.covidExternalLink} to="/contact/virtual-design-visit/">virtual design consultations</Link>
        </p>
      </section>
      <section className={covidStyles.covidSection}>
        <i>21st March 2020</i>
        <p>
          Wow Interior Design would like to reassure our customers that we are still open for business as usual and will continue to provide our products to our customers with the sales office remaining open.
          However all non-critical visits to our office have been postponed and our studio has been closed until further notice.
          We will continue to monitor the situation and follow government guidelines.
          We are trying to limit face to face contact and request prospective customers to contact us in the first instance by phone or email to discuss their requirements.
        </p>
      </section>
      <p>Please make contact on these emails:</p>
      <div>
        <div><a className={covidStyles.covidEmailLink} href="mailto:info@wowinteriordesign.com">info@wowinteriordesign.com</a></div>
        <div><a className={covidStyles.covidEmailLink} href="mailto:marketing@interiordesign.com">marketing@interiordesign.com</a></div>
        <div><a className={covidStyles.covidEmailLink} href="mailto:installations@interiordesign.com">installations@interiordesign.com</a></div>
      </div>
      <p>You can also contact our office on 01303 847480</p>
    </div>
  </div>
);
